import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    $("#inscription-form-submit").on('click', function(e) {
        var error = false;
        var error_txt = 'Verifiez ces données :';
        if (!$("input[name='user[first_name]']").val()) {
            error_txt = error_txt + '<br>- Prénom';
            error = true;
        }
        if (!$("input[name='user[last_name]']").val()) {
            error_txt = error_txt + '<br>- Nom';
            error = true;
        }
        if (!$("input[name='user[email]']").val()) {
            error_txt = error_txt + '<br>- Email';
            error = true;
        }

        if (!$("input[name='user[email_confirmation]']").val()) {
            error_txt = error_txt + '<br>- Email de confirmation';
            error = true;
        } else if ($("input[name='user[email_confirmation]']").val() != $("input[name='user[email]']").val()) {
            error_txt = error_txt + '<br>-Email et email de confirmation ne sont pas identiques';
            error = true;
        }

        if (!$("input[name='user[phone_number]']").val()) {
            error_txt = error_txt + '<br>- Téléphone';
            error = true;
        }
        if (!$("input[name='user[password]']").val()) {
            error_txt = error_txt + '<br>- mot de passe';
            error = true;

        }
        if (!$("input[name='user[password_confirmation]']").val()) {
            error_txt = error_txt + '<br>- mot de passe de confirmation';
            error = true;

        }
        if (!$("#accept_terms_checkbox").prop("checked")) {
            error_txt = error_txt + '<br>- Il faut accepter les conditions';
            error = true;
        }

        if (error == false) {
            $('#new_user').submit();
        } else {
            toastr.error(error_txt);
        }
    });
}