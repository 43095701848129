import FullCalendar from 'fullcalendar'
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { resourceTimeGridPlugin } from '@fullcalendar/resource-timegrid';
import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment';
export default function() {
    var calendarEl = document.getElementById('calendar');
    var calendar = new Calendar(calendarEl, {
        locale: frLocale,
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        header: {
            left: '',
            right: 'today',
            center: 'prev title next'
        },
        defaultView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        buttonIcons: {
            today: ' fas fa-redo fa-2x force-fw',
        },
        navLinks: false,
        editable: false,
        eventLimit: true, // allow "more" link when too many events
        height: 550,
        refetchEvents: true,
        contentHeight: 600,
        agendaEventMinHeight: 600,
        events: "/users/" + $('#calendar').data("id") + "/trainings.json",
        eventClick: function(event, jsEvent, view) {

            var dateTraining = moment(event.event._instance.range.start).format("YYYY-MM-DD")
            $.ajax({
                url: "/users/my_training?date=" + dateTraining,
                success: function(res) {
                    window.location.href = "/users/my_training?date=" + dateTraining
                }

            })
        },
        eventRender: function(event, eventElement) {
            if (event.event._def.extendedProps.imageurl != null) {
                event.el.getElementsByClassName("fc-content")[0].innerHTML += '<img src=' + event.event._def.extendedProps.imageurl + ' class="fc-cal-image">';
            }
            var dateTraining = moment(event.event._instance.range.start).format("YYYY-MM-DD")
            $(`.fc-day-top[data-date="${dateTraining}"]`).click(() => event.el.click())
        },
        eventColor: '#dfbb4d',
    });

    calendar.render();

};