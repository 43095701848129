import knob from "jquery-knob";
export default function() {
    $(".dial").prop('disabled', true);
    var objectifProgress = $("#objectif-progress").attr("aria-valuenow")

    function objectifProgressBar(objectifProgress) {
        $(".ob-progress-bar").animate({
            width: [objectifProgress + "%", "swing"],
            easing: 'linear',
        }, 1500);
    }
    objectifProgressBar(objectifProgress)
    var burntCals = $("#calories-burnt-progress").attr("aria-valuenow")
    $("#calories-burnt-progress").animate({
        width: [burntCals + "%", "swing"],
        easing: 'linear',
    }, 1500);
    var pgTime = $("#progress-time").attr("value");
    var displayVal = $("#progress-time").data("time-trained")

    function timeKnob(pgTime, displayVal) {
        $("#progress-time").knob({
            readOnly: true,
            height: 150,
            width: 150,
            value: 0,
            fontWeight: 900,
            font: "Montserrat",
            inputColor: "#000000",
            draw: function() {
                $(this.i).css('font-size', '16px');
            },
            format: function(value) {
                return displayVal;
            }
        }).animate({
            value: pgTime,
        }, {
            duration: 2000,
            easing: 'swing',
            value: displayVal,
            step: function() {
                $("#progress-time").val(Math.ceil(this.value)).trigger('change');
            }
        });
        $("#progress-time").val(displayVal)
    }
    timeKnob(pgTime, displayVal)
    var displayValTotal = $("#progress-time-total").data("time-trained")
    var pgTimeTotal = $("#progress-time-total").attr("value");

    function totalTimeKnob(pgTimeTotal, displayValTotal) {
        $("#progress-time-total").knob({
            readOnly: true,
            height: 150,
            width: 150,
            value: 0,
            fontWeight: 900,
            font: "Montserrat",
            inputColor: "#000000",
            fgColor: "#dfbb4d",
            draw: function() {
                $(this.i).css('font-size', '16px');
            },
            format: function(value) {
                return displayValTotal;
            }
        }).animate({
            value: pgTimeTotal,
        }, {
            duration: 2000,
            easing: 'swing',
            value: displayValTotal,
            step: function() {
                $("#progress-time-total").val(Math.ceil(this.value)).trigger('change');
            }
        });
        $("#progress-time-total").val(displayValTotal);
    }
    totalTimeKnob(pgTimeTotal, displayValTotal);
    var pgCalories = $("#progress-calories").attr("value");
    var displayCalVal = $("#progress-calories").data("calories-burnt")

    function progressCalories(pgCalories, displayCalVal) {
        $("#progress-calories").knob({
            readOnly: true,
            height: 150,
            width: 150,
            fgColor: '#dfbb4d',
            value: 0,
            fontWeight: 900,
            font: "Montserrat",
            inputColor: "#000000",
            draw: function() {
                $(this.i).css('font-size', '16px');
            },
            format: function(value) {
                return displayCalVal;
            }
        }).animate({
            value: pgCalories
        }, {
            duration: 2000,
            easing: 'swing',
            step: function() {
                $("#progress-calories").val(Math.ceil(this.value)).trigger('change');
            }
        });
    }
    progressCalories(pgCalories, displayCalVal)
    var pgCaloriesTotal = $("#progress-calories-total").attr("value");
    var displayCalValTotal = $("#progress-calories-total").data("calories-burnt")

    function progressTotalCalories(pgCaloriesTotal, displayCalValTotal) {
        $("#progress-calories-total").knob({
            readOnly: true,
            height: 150,
            width: 150,
            fgColor: '#dfbb4d',
            value: 0,
            fontWeight: 900,
            font: "Montserrat",
            inputColor: "#000000",
            draw: function() {
                $(this.i).css('font-size', '16px');
            },
            format: function(value) {
                return displayCalValTotal;
            }
        }).animate({
            value: pgCaloriesTotal
        }, {
            duration: 2000,
            easing: 'swing',
            step: function() {
                $("#progress-calories-total").val(Math.ceil(this.value)).trigger('change');
            }
        });
    }
    progressTotalCalories(pgCaloriesTotal, displayCalValTotal)
    let user_id = $(".user-profile").attr('id');
    $("#reset-stats").on("click", function() {
        $.ajax({
            url: user_id + "/reset_stats",
            type: "post",
            contentType: "application/json",
            dataType: "json",
            success: function(data) {
                $("#stats-partial").replaceWith(data.stats);
                timeKnob("0", "00:00:00");
                objectifProgressBar("0");
                totalTimeKnob("0", "00:00:00");
                progressCalories("0", "0");
                progressTotalCalories("0", "0");
            }
        });
        // }

    })

}