export default function() {
    $('.star').fontstar({
        // custom icons
        icon: "fa fa-3x fa-star-o",
        iconfull: "fa fa-3x fa-star",

        // color on hover
        hovercolor: "#dfbb4d",

        // star color
        starcolor: "#dfbb4d",

        // is selectable
        selectable: true
    }, function(value, self) {
        $(".sp-level-field").val(value)
    });
}