require("../common/card");

export default function() {

    $(document).ready(function() {
        let last4 = $("#card-form").data("number");
        let name = $("#card-form").data("name");
        let expiry = $("#card-form").data("expiry");
        let brand = '';
        let brand_data = $("#card-form").data("brand")
        switch (brand_data) {
            case 'visa':
                brand = 'visa';
                break;
            case 'mastercard':
                brand = 'mastercard';
                break;
            case 'amex':
                brand = 'amex';
                break;
            case 'discover':
                brand = 'discover';
                break;
            case 'dinersclub':
                brand = 'dinersclub';
                break;
            case 'jcb':
                brand = 'jcb';
                break;
            case 'unionpay':
                brand = 'unionpay';
                break;
            default:
                brand = '';
        }
        new Card({
            form: '#card-form',
            container: '.card-wrapper',

            placeholders: {
                number: '•••• •••• •••• ' + last4,
                name: name,
                expiry: expiry,
                cvc: '***'
            }
        });
        $(".jp-card").addClass('jp-card-' + brand + '').addClass('jp-card-identified');
        $(".card-wrapper").show('fast')

    })


    var stripe = Stripe($("meta[name='stripe-publishable-key']").attr("content"));
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    var style = {
        base: {
            color: '#303238',
            fontSize: '16px',
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#e5424d',
            ':focus': {
                color: '#303238',
            },
        },
    };

    // Create an instance of the card Element
    var card = elements.create('card', { style: style });

    let name = $("#card-form").data("name");
    // Add an instance of the card Element into the `card-element` <div>
    card.mount('#card-element');

    var cardButton = document.getElementById('submit-stripe-btn');
    var resultContainer = document.getElementById('card-result');
    const form = document.querySelector("#card-form")
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'card_token')
    form.appendChild(hiddenInput);
    cardButton.addEventListener('click', function(ev) {

        stripe.createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
                name: name,
            },
        }).then(function(result) {
            if (result.error) {
                // Display error.message in your UI
                resultContainer.textContent = result.error.message;
            } else {
                // You have successfully created a new PaymentMethod
                if (result.paymentMethod && result.paymentMethod.id != null) {
                    hiddenInput.setAttribute('value', result.paymentMethod.id);
                    form.submit()
                } else {
                    resultContainer.textContent = "Veuillez réessayer"
                }
            }
        });
    });
}