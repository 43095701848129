import Cookie from 'cookie.js'
import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    let vid = $(".video-js")

    vid.on('ended', function() {
        $(".bsm-btn").click()
    })

    $(".bsm .act-btn").click(function() {
        var link = $(this).data('href');
        $.ajax({
            url: link,
            type: "post",
            contentType: "application/json",
            dataType: "json",
            success: function(data) {
                Cookie.set('change_level_cookie', false)
                $(".close").click()
                if (data.type == "initialize") {
                    toastr.success("votre objectif est initialisé avec le mème niveau sportif");
                } else if (data.type == "upgrade") {
                    toastr.success("Vous avez passer au niveau suivant");
                }
            }
        });

    })
}