import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    $(':input[type="number"]').on('mousewheel', function(event) {
        console.log(event.deltaX, event.deltaY, event.deltaFactor);
    });

    $("#bodyStateNav").click(function() {
        $('html, body').animate({
            scrollTop: $("#bodyState").offset().top
        }, 1000);
    });
    $("#subscriptionNav").click(function() {
        $('html, body').animate({
            scrollTop: $("#subscription").offset().top
        }, 1000);
    });


    const editPersonal = document.querySelector(".editAccount");
    editPersonal.addEventListener("ajax:success", (event) => {
        const evDetails = event.detail[0];
        console.log(event);
        if (evDetails.success == true) {
            toastr.success(evDetails.message);
        } else {
            toastr.error(evDetails.message);
        }
    });

    const editBs = document.querySelector(".edit_body_state");
    editBs.addEventListener("ajax:success", (event) => {
        const evDetails = event.detail[0];
        console.log(event);
        if (evDetails.success == true) {
            toastr.success(evDetails.message);
        } else {
            toastr.error(evDetails.message);
        }
    });
}