export default function() {
    $(".objectif-tag").hover(
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let oblink = $(this).find($(".category-btn"));

            desc.show()
            oblink.show()
            catName.addClass("fitaya-color")
        },
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let oblink = $(this).find($(".category-btn"));
            desc.hide()
            oblink.hide()
            catName.removeClass("fitaya-color")
        }
    );
}