import Cookie from 'cookie.js'
import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    let vid = $(".video-js")
    $( "#obdoneModal" ).modal({backdrop: 'static', keyboard: false})


    vid.on('ended', function() {
        $(".obd-btn").click()
    })
    $(".obd-btn").click()
    $(".obd .act-btn").click(function() {
        var link = $(this).data('href');
        $.ajax({
            url: link,
            type: "post",
            contentType: "application/json",
            dataType: "json",
            success: function(data) {
                $(".close").click()
                if (data.type == "initialize") {
                    Cookie.set('obdone_cookie', false)
                    toastr.success("votre programme est initialisé");
                }
            }
        });
    })
}