import "slick-carousel"
export default function() {
    $('.coaches-carousel').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        prevArrow: '<i class="fas fa-chevron-left fitaya-color fa-3x m-auto"></i>',
        nextArrow: '<i class="fas fa-chevron-right fitaya-color fa-3x m-auto"></i>'
    });

    $('.guests-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        prevArrow: '<i class="fas fa-chevron-left fitaya-color fa-3x m-auto"></i>',
        nextArrow: '<i class="fas fa-chevron-right fitaya-color fa-3x m-auto"></i>'
    });


}