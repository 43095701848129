require("../common/card");
import toastr from "toastr";
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    var stripe = Stripe($("meta[name='stripe-publishable-key']").attr("content"));
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    var style = {
        base: {
            color: '#303238',
            fontSize: '16px',
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#e5424d',
            ':focus': {
                color: '#303238',
            },
        },
    };

    // Create an instance of the card Element
    var card = elements.create('card', { style: style });

    // Add an instance of the card Element into the `card-element` <div>
    card.mount('#card-element');

    var cardButton = document.getElementById('submit-stripe-btn');
    var resultContainer = document.getElementById('card-result');
    const form = document.querySelector("#card-form")
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'card_token')
    form.appendChild(hiddenInput);

    cardButton.addEventListener('click', function(ev) {
        let name = form.querySelector("#name_on_card").value
        stripe.createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
                name: name,
            },
        }).then(function(result) {
            if (result.error) {
                // Display error.message in your UI
                resultContainer.textContent = result.error.message;
            } else {
                // You have successfully created a new PaymentMethod
                if (result.paymentMethod && result.paymentMethod.id != null) {
                    hiddenInput.setAttribute('value', result.paymentMethod.id);
                    Rails.fire(form, 'submit');
                    cardButton.disabled = true
                    submitCb(form)
                } else {
                    resultContainer.textContent = "Veuillez réessayer"
                }
            }
        });
    });

    function submitCb(form) {
        form.addEventListener("ajax:success", (event) => {
            const evDetails = event.detail[0];
            console.log(event);
            if (evDetails.success == true) {
                toastr.success(evDetails.message);
            } else {
                toastr.error(evDetails.message);
            }
            cardButton.disabled = false
        });
    }

}