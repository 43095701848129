window.previewImage = previewImage;

function previewImage(input, elem) {
    var reader = new FileReader();
    var preview = $(elem);
    var dataurl;
    if (input.files && input.files[0]) {
        reader.onload = function(e) {
            preview.attr('src', e.target.result);

        }
    };
    reader.readAsDataURL(input.files[0]);
}



// (function( $ ){
//     $.fn.rotate = function(deg) {
//         this.css({'transform': 'rotate('+deg+'deg)'});
//         this.css({'-ms-transform': 'rotate('+deg+'deg)'});
//         this.css({'-moz-transform': 'rotate('+deg+'deg)'});
//         this.css({'-o-transform': 'rotate('+deg+'deg)'}); 
//         this.css({'-webkit-transform': 'rotate('+deg+'deg)'});
//         return this; 
//     };
// })( jQuery );

// preview.removeClass("flip rotate-180 flip-and-rotate-180 flip-and-rotate-270 rotate-90 flip-and-rotate-90 rotate-270");
// EXIF.getData(input.files[0], function() {
//     switch(parseInt(EXIF.getTag(this, "Orientation"))) {
//         case 2:
//             preview.addClass('flip'); break;
//         case 3:
//             preview.addClass('rotate-180'); break;
//         case 4:
//             preview.addClass('flip-and-rotate-180'); break;
//         case 5:
//             preview.addClass('flip-and-rotate-270'); break;
//         case 6:
//             preview.addClass('rotate-90'); break;
//         case 7:
//             preview.addClass('flip-and-rotate-90'); break;
//         case 8:
//             preview.addClass('rotate-270'); break;
//     }
// });
// preview.css('background', 'linear-gradient(rgba(17, 0, 55, .2), rgba(0, 13, 55, .2)), url(' + reader.result + ')');