import videojs from 'video.js';
import toastr from "toastr"

export default function () {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-left",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    videojs(document.querySelector('.video-js'));
    $(".video-js").each(function (videoIndex) {
        var videoId = $(this).attr("id");
        var timeCounter = 0;
        var intervalID = null;

        intervalManager(true);

        videojs(videoId).on('timeupdate', (e) => {
            timeCounter += 1;
        });

        videojs(videoId).on('waiting', (e) => {
            intervalManager(false);
        });

        videojs(videoId).on('play', (e) => {
            console.log("run interval")
            intervalManager(true);
        });

        videojs(videoId).on('pause', (e) => {
            intervalManager(false);
        });

        function intervalManager(flag) {
            if (flag)
                intervalID = setInterval(() => {
                    var duration = timeCounter / 4;
                    timeCounter = 0;
                    sendDuration(duration)
                }, 5000)
            else
                clearInterval(intervalID);
        }

        videojs(videoId).ready(function () {
            this.on("play", function (e) {
                $(".video-js").each(function (index) {
                    if (videoIndex !== index) {
                        this.player.pause();
                    }
                });
            });
        });

        function sendDuration(duration) {
            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: `/daily_watch_times/set_watched_duration?duration=${duration}`,
                success: function (response) {
                    if (!response.success) {
                        toastr.warning(response.message);
                        setTimeout(() => {
                            window.location = "/users/daily"
                        }, 3000)
                    }
                },
                error: function (response) {
                }
            });
        }

    });

    $(document).on('click', ".vjs-big-play-button", function () {
        let vidId = $(this).parent().data("id");

        let obj = $(this);
        let pass = false;
        if ($(this).parent().hasClass('freevideo')) {
            pass = true;
        }
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/watched_videos?video_id=' + vidId + '&pass=' + pass,
            success: function (response) {
                obj.find('.vjs-icon-placeholder').hide();
                console.log(response);
                toastr.success(response.message);
            },
            error: function (response) {
                obj.find('.vjs-icon-placeholder').hide();
                toastr.warning(response.message);
            }
        });
    })

    $('#video-modalFree').on('hidden.bs.modal', function () {
        // do something…
        console.log('ferme modal, stop du lecteur');
        //document.getElementById('freevideo').pause();
        setTimeout(function () {
            $('.vjs-tech').pause();
        }, 1000);

    })


    $(".category-tag").hover(
        function () {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let favBtn = $(this).find($(".custom-fav"));
            desc.show()
            favBtn.show()
            catName.hide()
        },
        function () {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let favBtn = $(this).find($(".custom-fav"));
            desc.hide()
            favBtn.hide()
            catName.show()
        }
    );
}
