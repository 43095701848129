export default function() {
    $(".mute").hide()
    $("#playpause").click(function() {
        let video = $("#presentation").get(0);
        if (video.paused || video.ended) {
            this.title = "pause";
            video.play();
        } else {
            this.title = "play";
            video.pause();
        }
    })
    $(".mute-video").click(function(e) {
        let video = $("#presentation");
        e.preventDefault();
        if (video.prop('muted') == false) {
            $(".mute").show()
            $(".unmute").hide()
            video.prop('muted', true);
        } else if (video.prop('muted')) {
            $(".mute").hide()
            $(".unmute").show()
            video.prop('muted', false);
        }
    });
}