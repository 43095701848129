import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    $(document).on('click', '.fav-video', function(e) {
        let video_data = $(this).data("video")
        let lin_fav = $(this)
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/videos/' + video_data + '/favorite',
            success: function(response) {
                if (response.type == "favor") {
                    let unlikedFont = lin_fav.find($(".far"))
                    lin_fav.removeClass("unliked").addClass("liked");
                    unlikedFont.removeClass("far")
                    unlikedFont.addClass("fa")
                    toastr.success(response.message);
                } else if (response.type == "unfavor") {
                    let likedFont = lin_fav.find($(".fa"))
                    lin_fav.removeClass("liked").addClass("unliked");
                    likedFont.removeClass("fa")
                    likedFont.addClass("far")
                    toastr.warning(response.message);
                }
            }
        });
    });
}