export default function() {
    var program_id = $(".n-cont").data("nutrition");
    $("#couponCode").change(function(o) {
        var new_link = '/nutrition_programs/' + program_id + '/calculate_price?couponCode=' + o.target.value
        $("#calculate-price-link").attr('href', new_link);
    });
    $("#calculate-price-link").click(function() {
        var link = $("#calculate-price-link").attr('href');
        $.ajax({
            url: link,
            type: "post",
            contentType: "application/json",
            dataType: "json",
            success: function(data) {
                $(".submit-stripe-btn").text("Payer " + data.price + "€")
                $("#error-promo-code").text(data.message)
            }
        });
    });

    $(".nav-link-days").click(function() {
        let actifTabId = $(this).attr("id").replace('-tab', '')
        $(".list-tab-pane").each(function() {
            $(this).removeClass("show active")
        })
        $('#' + actifTabId + '').addClass("show active")
    })

    $(".category-tag").hover(
        function() {
            let catName = $(this).find($(".nut-title"));
            let desc = $(this).find($(".nut-desc"));
            let price = $(this).find($(".nut-price"));
            let nutBtn = $(this).find($(".category-btn"));
            desc.show()
            price.show()
            nutBtn.show()
            catName.hide()
        },
        function() {
            let catName = $(this).find($(".nut-title"));
            let desc = $(this).find($(".nut-desc"));
            let price = $(this).find($(".nut-price"));
            let nutBtn = $(this).find($(".category-btn"));
            desc.hide()
            price.hide()
            nutBtn.hide()
            catName.show()
        }
    );
}