import "slick-carousel"
export default function() {
    $('.clickToggle').click(function(){

        $(this).parent().parent().next('.details-container').toggle();



    });

}