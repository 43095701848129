import toastr from "toastr";
import moment from 'moment';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    
    var d = new Date()
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    flatpickr(".birthdate-pick", {
        "minDate": new Date(year - 98, month, day),
        "maxDate": new Date(year - 15, month, day),
        "dateFormat": "d/m/Y",
        "locale": French
    });

    $("input[name='body_state[height]']").on('change paste keyup', function(event) {
        if ($(this).val().length > 3 || $(this).val() > 250) {
            let str = $(this).val()
            toastr.error('Votre taille ne doit pas dépasser 250cm');
            $(this).val(str.substring(0, str.length - 1))
        }
    })

    $("input[name='body_state[weight]']").on('change paste keyup', function(event) {
        if ($(this).val().length > 3 || $(this).val() > 200) {
            let str = $(this).val()
            toastr.error('Votre poids ne doit pas dépasser 200kg');
            $(this).val(str.substring(0, str.length - 1))
        }
    })

    $("#submit-bs").on('click', function(e) {
        var error = false;

        if (!$("#body_state_gender_homme").prop('checked') && !$("#body_state_gender_femme").prop('checked')) {
            toastr.error('Veuiller choisir votre sexe');
            error = true;


        } else if (!$("input[name='body_state[birthdate]']").val()) {
            toastr.error('Veuiller entrer votre date de naissance');
            error = true;
        } else if (!$("input[name='body_state[height]']").val()) {
            toastr.error('Veuiller entrer votre taille');
            error = true;

        } else if (!$("input[name='body_state[weight]']").val()) {
            toastr.error('Veuiller entrer votre poids');
            error = true;

        }

        if (error == false) {
            $('form').submit();
        }
    });
}