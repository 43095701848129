export default function() {
    let first_option = $("#motif-select option:first");
    $("#motif-select").prop("required", true)
    first_option[0].disabled = true
    if ($("#motif-select").children("option:selected").text() == first_option.html()) {
        $("#motif-select").css("color", "#6c757d");
        $("#motif-select").children().not('option:first').css("color", "black")
    }
    $("#motif-select").change(function() {
            $("#motif-select").css("color", "black")
        }

    )

}