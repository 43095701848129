import { ajax } from "jquery";
import toastr from "toastr";
export default function() {
    $('#genderModal').modal({
        backdrop: 'static',
        keyboard: false
    })
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    $(".save-gender").attr('disabled', true)
    $("input[type=radio]").change(function() {
        $(".save-gender").attr('disabled', false)
    })
    $("#update-gender-form").on('ajax:success', function(data, status, xhr) {
        $("#genderModal").modal('hide')
        toastr.success(data.detail[0].message);
    });
}