export default function () {
    $(".btn-next").attr('disabled', 'disabled');
    $(".btn-next-time").attr('disabled', 'disabled');
    $(".btn-next").click(function () {
        $(".form-dispo").show();
        $(".days-count-container").hide();
        $(".time-form").hide();
    })
    $(".btn-next-time").click(function () {
        $(".form-dispo").hide();
        $(".days-count-container").hide();
        $(".time-form").show();
    })

    $("#confirme-program").click(()=> {
        $("#short-programs-text").hide()
    })

    $("input[name='disponibility[days][]']").change(function () {
        let checked = $('input:checkbox:checked').length;
        if (($("#three-days").prop('checked') && checked == 3) || ( $("#five-days").prop('checked') && ( checked == 5 || checked == 3 ) ) ) {
            $(".btn-next-time").removeAttr('disabled');
        }
        else if($('#nbDaysToCheck').val() == checked ){
            $(".btn-next-time").removeAttr('disabled');
        }
        else {
            $(".btn-next-time").attr('disabled', 'disabled');
        }
        if ($("#three-days").prop('checked')) {
            if ($(".custom-control-input:checked").length >= 3) {
                $("input[name='disponibility[days][]']:not(:checked)").attr('disabled', 'disabled');
            } else {
                $("input[name='disponibility[days][]']").removeAttr('disabled');
            }
        } else if ($("#five-days").prop('checked')) {
            if ($(".custom-control-input:checked").length >= 5) {
                $("input[name='disponibility[days][]']:not(:checked)").attr('disabled', 'disabled');
            } else {
                $("input[name='disponibility[days][]']").removeAttr('disabled');
            }
        }
        else if ($('#nbDaysToCheck')){
            if ($('#nbDaysToCheck').val() <= checked) {
                $("input[name='disponibility[days][]']:not(:checked)").attr('disabled', 'disabled');
            }
            else {
                $("input[name='disponibility[days][]']").removeAttr('disabled');
            }
        }
    })

    $("#timeInput").val($(".tc-hours").text().trim() + ':' + $(".tc-minutes").text().trim())

    $(".hours-up").click(function () {
        let cValue = $(".tc-hours").text()
        let strVal = cValue
        let val = parseInt(cValue)
        if ((val >= 0) && (val < 23)) {
            let final_val = val + 1
            if (final_val < 10) {
                strVal = '0' + final_val + ''
            } else {
                strVal = final_val
            }
        }
        $(".tc-hours").text(strVal)
    })

    $(".hours-down").click(function () {
        let cValue = $(".tc-hours").text()
        let strVal = cValue
        let val = parseInt(cValue)
        console.log(val);
        if ((val > 0) && (val <= 23)) {
            let final_val = val - 1
            if (final_val < 10) {
                strVal = '0' + final_val + ''
            } else {
                strVal = final_val
            }
        }
        $(".tc-hours").text(strVal)
    })

    $(".minutes-up").click(function () {
        let cValue = $(".tc-minutes").text()
        let strVal = cValue
        let val = parseInt(cValue)
        if ((val >= 0) && (val < 59)) {
            let final_val = val + 1
            if (final_val < 10) {
                strVal = '0' + final_val + ''
            } else {
                strVal = final_val
            }
        }
        $(".tc-minutes").text(strVal)
    })

    $(".minutes-down").click(function () {
        let cValue = $(".tc-minutes").text()
        let strVal = cValue
        let val = parseInt(cValue)
        if ((val > 0) && (val <= 59)) {
            let final_val = val - 1
            if (final_val < 10) {
                strVal = '0' + final_val + ''
            } else {
                strVal = final_val
            }
        }
        $(".tc-minutes").text(strVal)
    })

    $(document).on('click', ".hours-up, .hours-down, .minutes-up, .minutes-down", function () {
        $("#timeInput").val($(".tc-hours").text().trim() + ':' + $(".tc-minutes").text().trim())
        console.log($("#timeInput").val());
    })
}