export default function() {
    $(".category-tag").hover(
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let favBtn = $(this).find($(".custom-fav"));
            desc.show()
            favBtn.show()
            catName.hide()
        },
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            let favBtn = $(this).find($(".custom-fav"));
            desc.hide()
            favBtn.hide()
            catName.show()
        }
    );

    $(document).on('click', ".category-tag-redir", function() {
        let vid_id = $(this).attr("id");
        window.location.href = '/videos/' + vid_id + ''
    })
}