import toastr from "toastr"
export default function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    $("#avatar").change(function() {
        const nativeFormEl = $("#avatar-form")[0]
        Rails.fire(nativeFormEl, 'submit')
    })

    const element = document.querySelector("#avatar-form");
    element.addEventListener("ajax:success", (response) => {
        toastr.success(response.detail[0].message);
        $("#avatar").prop("disabled", false);
    });
}