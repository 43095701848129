// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("chartkick")
require("chart.js")
require("toastr")
require('!style-loader!css-loader!video.js/dist/video-js.css')
window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import 'modules'
import 'common/upload_images'
import 'common/card'
import 'common/jquery.fontstar.js'
import 'modules/timepicki.js'
import videojs from 'video.js';
import 'jquery-mousewheel';
import toastr, { success } from "toastr";
// import "izimodal/js/iziModal.js";

$(document).on("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip({
        placement: 'left',
        arrow: false,
        template: '<div class="tooltip md-tooltip-email"><div class="tooltip-arrow md-arrow"></div><div class="tooltip-inner md-inner-email text-dark text-uppercase"></div></div>',
    })
    $('[data-toggle="popover"]').popover()
})

/* start dot nav */
$(document).on("turbolinks:load", () => {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    // $('#timepicker').timepicker();
    //     $('.awesome-tooltip').tooltip()
    //     $(window).bind('scroll', function(e) {
    //         clearTimeout($.data(this, 'scrollTimer'));
    //         $.data(this, 'scrollTimer', setTimeout(function() {
    //             // do something
    //             dotnavigation();
    //         }, 250));
    //     });

    $(".video-js").on("contextmenu", function() {
        return false;
    });
    $('#timepicker').timepicki({
        show_meridian: false,
        min_hour_value: 0,
        max_hour_value: 23,
        step_size_minutes: 15,
        overflow_minutes: true,
        increase_direction: 'up',
        disable_keyboard_mobile: true
    });
    $(".custom-control-input").hide();
    var numSections = $('section').length;
    console.log(numSections);

    // $(window).scroll(function() {
    //     $('section').each(function(i, item) {
    //         var ele = $(item);
    //         var nextTop;
    //         var thisTop;

    //         if (typeof ele.next().offset() != "undefined") {
    //             if (ele.next().offset().top == 0) {
    //                 nextTop = $(document).height();
    //             } else {
    //                 nextTop = ele.next().offset().top;
    //             }
    //         } else {
    //             nextTop = $(document).height();
    //         }

    //         if (ele.offset() !== null) {
    //             thisTop = ele.offset().top - ((nextTop - ele.offset().top) / numSections);
    //         } else {
    //             thisTop = 0;
    //         }

    //         var docTop = $(document).scrollTop();

    //         if (docTop >= thisTop && (docTop < nextTop)) {
    //             $('.nav-links-bar a').removeClass('active');
    //             $('.nav-links-bar a').eq(i).addClass('active');
    //         }
    //     });
    // });

    //     /* get clicks working */
    $('.nav-links-bar.home a').click(function() {
        $('.nav-links-bar a').removeClass('active');
        $(this).addClass('active');
        var id = $(this).attr("href").replace("/", ""),
            posi,
            ele,
            padding = 30;

        ele = $(id);
        posi = ($(ele).offset() || 0).top - padding + 20;
        $('html, body').animate({ scrollTop: posi }, 'slow');

        return false;
    });

    if (window.location.pathname == "/users/daily") {
        $("#daily_link").addClass("active");
    } else if (window.location.pathname == "/videos/category_videos" || window.location.href.indexOf('videos') > 0) {
        $("#cat_videos_link").addClass("active")
    } else if ((window.location.pathname == "/users/nutrition") || (window.location.href.indexOf('nutrition_programs') > 0) || (window.location.href.indexOf('repas') > 0) || (window.location.href.indexOf('courses') > 0)) {
        $("#nutrition_link").addClass("active")
    } else if (window.location.pathname == "/users/agenda") {
        $("#agenda_link").addClass("active");
    } else if (window.location.pathname == "/users/edit_account") {
        $("#profil_link").removeClass("active");
    } else if (window.location.href.indexOf('users') > 0) {
        $("#profil_link").addClass("active");
    } else if (window.location.href.indexOf('objectifs') > 0) {
        $("#objectif_link").addClass("active");
    }
    /* end dot nav */

    $(".sub-btn-modal").click()
    $(".conc-btn-modal").click()

    $('#newCguModal').modal({
        backdrop: 'static',
        keyboard: false
    })

    $(".save-new-cgu").attr('disabled', true)
    $("#new_cgu_accepted_at").change(function() {
        if ($(this).prop("checked")) {
            $(".save-new-cgu").attr('disabled', false)
        } else {
            $(".save-new-cgu").attr('disabled', true)
        }
    });

    $('#proceedPayment').modal({
        backdrop: 'static',
        keyboard: false
    })

    $(".flash-msg").each(function(index) {
        let msgType = $(this).data("msgtype")
        let msg = $(this).data("msg")
        if (msgType == "success" || msgType == "notice") {
            toastr.success(msg);
        } else {
            toastr.error(msg);
        }
    });

    const urlParams = new URLSearchParams(window.location.search);
    let aff = urlParams.get('affiliate');
    console.log(aff);
    if (aff != null) {
        $(".aff-link").each(function() {
            var $this = $(this);
            var _href = $this.attr("href"); 
            $this.attr("href", _href + '?affiliate='+ aff +'');
        });
    }

    $(document).on('click', '.resetPopup', function() {
        $.ajax({
          url: "/users/reset_popup", // The URL of your reset_popup route
          method: 'POST',      // Use POST or other appropriate HTTP method
          success: function() {
            // Handle success, if needed
            console.log('Popup reset successfully.');
          },
          error: function() {
            // Handle errors, if needed
            console.error('Error resetting popup.');
          }
        });
    });

});