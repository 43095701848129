import "slick-carousel"
export default function() {
    $(".category-tag").hover(
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            desc.show()
            catName.addClass("fitaya-color")
        },
        function() {
            let catName = $(this).find($(".categ-name"));
            let desc = $(this).find($(".categ-description"));
            desc.hide()
            catName.removeClass("fitaya-color")
        }
    );

    $(".categ-pill-btn").click(function() {
        let actifTabId = $(this).attr("id").replace('-tab', '')
        $(".categ-tab-pane").each(function() {
            $(this).removeClass("show active")
        })
        $('#' + actifTabId + '').addClass("show active")
    })

    $(document).on('click', ".cat-video-link", function() {
        let cat_id = $(this).attr("id");
        window.location.href = '/categories/' + cat_id + '/videos'
    })

    $('.categories-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        prevArrow: '<i class="fas fa-chevron-left fitaya-color fa-3x m-auto"></i>',
        nextArrow: '<i class="fas fa-chevron-right fitaya-color fa-3x m-auto"></i>'
    });
}