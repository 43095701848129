import toastr from "toastr"
export default function() {
    $(window).on("load resize", function() {
        let bgImg = $(".daily-header").data("bgurl")
        let respoImg = $(".daily-header").data("respobg")
        let scWidth = screen.width
        console.log(scWidth);
        if (scWidth > 991 ) {
            $(".daily-header").css("background-image", 'url('+bgImg+')')
        } else {
            $(".daily-header").css("background-image", 'url('+respoImg+')')
        }
    })
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    $(document).on('click', '#gameParticipate', function(e) {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/users/participate',
            success: function(response) {
                $("#gameParticipate").attr("disabled", true);
                toastr.success(response.message);
                $("#gameParticipate").text("Vous participez au jeu concours. Le tirage au sort aura lieu le 30 septembre. Bonne chance ! ✌🏻")
            }
        });
    })
    $(document).on('click', '#participateConcModal', function(e) {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/users/participate',
            success: function(response) {
                $("#gameParticipate").attr("disabled", true);
                toastr.success(response.message);
                $("#gameParticipate").text("Vous participez au jeu concours. Le tirage au sort aura lieu le 30 septembre. Bonne chance ! ✌🏻")
            }
        });
    })
    $(document).on('click', '#denyConcModal', function(e) {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/users/decline_participation',
            success: function(response) {
                toastr.success(response.message);
            }
        });
    })
}